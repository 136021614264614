/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
let e;
function t() {
  return e || (e = l()), e;
}
class r {
  constructor() {
    this.available = !1, this.majorPerformanceCaveat = !1, this.maxTextureSize = 0, this.supportsVertexShaderSamplers = !1, this.supportsHighPrecisionFragment = !1, this.supportsColorBufferFloat = !1, this.supportsColorBufferFloatBlend = !1, this.supportsColorBufferHalfFloat = !1;
  }
}
function o(e) {
  if ("undefined" == typeof WebGL2RenderingContext) return null;
  const t = document.createElement("canvas");
  if (!t) return null;
  let r = t.getContext("webgl2", {
    failIfMajorPerformanceCaveat: !0
  });
  if (null == r && (r = t.getContext("webgl2"), null != r && (e.majorPerformanceCaveat = !0)), null == r) return r;
  e.available = !0, e.maxTextureSize = r.getParameter(r.MAX_TEXTURE_SIZE), e.supportsVertexShaderSamplers = r.getParameter(r.MAX_VERTEX_TEXTURE_IMAGE_UNITS) > 0;
  const o = r.getShaderPrecisionFormat(r.FRAGMENT_SHADER, r.HIGH_FLOAT);
  return o && (e.supportsHighPrecisionFragment = o.precision > 0), r;
}
function l() {
  const e = new r(),
    t = o(e);
  return null == t || (e.supportsColorBufferFloat = null !== t.getExtension("EXT_color_buffer_float"), e.supportsColorBufferFloatBlend = null !== t.getExtension("EXT_float_blend"), e.supportsColorBufferHalfFloat = e.supportsColorBufferFloat || null !== t.getExtension("EXT_color_buffer_half_float")), e;
}
export { t as getWebGLCapabilities };